@import './variables.scss';
@import './mixins.scss';

#visitors-photo-row {

  img {
    width: 100%;
    height: auto;
    max-width: 300px;
    margin-bottom: 15px;
  }
}

#visitors-data-row {

}

.visitors {

  .full-height {
    height: 100%;
  }

  .visitor-card {

    flex-grow: 0;

    .card-body {
      box-shadow: 0px 3px 20px rgba(0,0,0, 0.2);
    }

    .cell-size {
      width: 250px;
      height: 250px;
      margin: 25px;
    }

    .img-bg {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .name {
      letter-spacing: 4px;
      color: $fc-gold;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
    }

    &-banner-youth {
      background-image: url(../images/ministries/banner-youth-ministries.png);
      @include ministry-banner();

      h2 {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 150px;
        line-height: 150px;
        background-color: rgba($fc-darkblue, 0.8);
        text-transform: none;
        font-family: BeyondInfinity;
        font-size: 60px;
        color: $fc-gold;
        text-align: center;
      }
    }
  }

  img {
    width: 100%;

  }

  .contact {
    background-image: url(../images/visitors/card-contact.png);
    border-radius: 8px;
  }

  .what-to-expect {
    background-image: url(../images/visitors/card-what-to-expect.png);
    border-radius: 8px;
  }

  .leadership {
    background-image: url(../images/visitors/card-leadership.png);
    border-radius: 8px;
  }

  .missions {
    background-image: url(../images/visitors/card-missions.png);
    border-radius: 8px;
  }

  .what-we-believe {
    background-image: url(../images/visitors/card-what-we-believe.png);
    border-radius: 8px;
  }
}

.missions-container {
  .alliance-image {
    max-width: 400px;
    width: 90%;
    height: auto;

  }
}

.leader {
  &-photo {
    max-width: 200px;
    max-height: 200px;
    border-radius: 8px;
  }
  &-name {
    font-weight: bold;
    color: $fc-darkblue;
    font-size: 18px;
  }
  &-title {
    font-size: 16px;
    font-weight: normal;
    color: #000000;
  }
}
