@import './variables.scss';
@import './mixins.scss';

.gold {
  color: $fc-gold;
}

.text-shadow {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.box-shadow {
  box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.16);
}

.rounded-corners {
  border-radius: $fc-border-radius;

  img {
    border-radius: $fc-border-radius;
  }
}

.clean-link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.breadcrumb {
  background-color: #ffffff;
  display: block;

  .active {
    color: $fc-mediumgray;
  }
}

.fc-bg-primary {
  background-color: $fc-blue;
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
}

.fch-button {
  padding: 20px 70px;
  font-family: Montserrat;
  font-weight: bold;
  @include fluid-type($min_width, $max_width, 10px, 16px);
  text-transform: uppercase;
  letter-spacing: 4px;
  color: $fc-darkblue;
  border: 1px solid $fc-darkblue;
  border-radius: 80px;
  display: inline-block;

  @media (max-width: 425px) {
    padding: 5px 10px;
  }
  @media (min-width: 426px) and (max-width: 800px) {
    padding: 10px 25px;
  }

  &:hover {
    text-decoration: none;
    color: $fc-darkblue;
  }
}

.swap-cols-mobile {

  .row {
    .col-sm-9 {
      order: 0;

      @include w-phone {
        order: 1;
      }
    }

    .col-sm-3 {
      order: 1;

      @include w-phone {
        order: 0;
      }
    }
  }
}

.abf-title-image {
  img {
    max-width: 1125px;
    width: 100%;
    height: auto;

    @include w-phone {
      max-width: 360px;
    }
  }
}