@import './variables.scss';

.btn-primary {
  background-color: $fc-blue;
}

.btn-primary:disabled {
  background-color: lighten($fc-blue, 25%);
  border-color: lighten($fc-blue, 25%);
}

.btn-primary:active, .btn-primary:hover {
  background-color: darken($fc-blue, 10%); //
  border-color: darken($fc-blue, 10%);
}

.page-item.active .page-link {
  background-color: $fc-blue;
  border-color: $fc-blue;
}

.page-item {
  .page-link {
    color: $fc-blue;
  }
}