@import './variables.scss';
@import './mixins.scss';

.fc-call-to-action {

  .fc-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $fc-lightgray;
    max-height: 150px;

    @include w-phone {
      align-items: flex-start;
      flex-wrap: wrap;
      max-height: 175px;
    }
  }

  .prefix {
    color: $fc-darkblue;
    font-size: 20px;
    letter-spacing: 6.25px;
    text-transform: uppercase;

    @include w-phone {
      margin-top: 37px;
    }
  }

  .suffix {
    color: $fc-gold;
    font-family: BeyondInfinity;
    font-size: 85px;
    padding: 15px 25px 0px 25px;

    @include w-phone {
      font-size: 75px;
      padding: 0px 25px;
    }
  }

  .fch-button {
    background-color: transparent;

    @include w-phone {
      margin-top: -15px;
      margin-bottom: 30px;
      padding: 15px;
    }
  }
}