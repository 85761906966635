@import './variables.scss';
@import './mixins.scss';

.sermon-container, .sermon-container-sm {
  max-width: 495px;
  padding: 10px 0px 0px 0px;
  margin: 0 auto;

  @include w-phone {
    max-width: 375px;
  }

  .thumbnail-container {
    width: 495px;
    height: 275px;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 8px;
    margin-bottom: 10px;
    background-size: cover;
    background-position: 50% 50%;

    @include w-phone {
      width: 375px;
    }
    @include w-custom-max(375px) {
      margin-left: -15px;
      margin-right: -15px;
    }

    .thumbnail {
      border-radius: 8px;
    }

    .thumbnail-overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba($fc-darkblue,0.7);
      border-radius: 8px;
      z-index: 1;

      button {
        border: none;
        background-color: transparent;
        padding: 0px;
        color: $fc-white;

        &:focus {
          outline: none;
        }
      }

      .rewind, .forward {
        margin: 0px 15px;

        i {
          font-size: 45px;
        }
      }

      .download {
        position: absolute;
        width: 32px;
        height: 32px;
        right: 10px;
        bottom: 35px;
        color: $fc-white;

        i {
          font-size: 32px;
          opacity: 0.5;

          &:hover {
            opacity: 1.0;
          }
        }
      }

      .play-button {
        &:active {
          color: rgba($fc-white, 0.8);
        }

        i {
          font-size: 70px;
        }
      }

      .speed-container {
        position: absolute;
        bottom: 45px;
        left: 10px;

        .speed-btn {
          color: rgba($fc-white, 0.5);
        }

        .current-speed {
          color: white;
        }
      }

      .progress-container {
        position: absolute;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0,0,0,0.5);
        width: 100%;
        height: 40px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin: 0;

        .elapsed, .duration {
          color: $fc-white;
          padding: 0 10px;
          font-family: 'Ubuntu Mono', monospace;
        }

        .progress-bg {
          background-color: rgba($fc-white, 0.25);
          border-radius: 3px;
          // width: 100%;
          padding: 0px;
          height: 20px;

          .progress-level {
            background-color: rgba($fc-white, 0.8);
            border-radius: 3px;
            height: 20px;
            transition: width .15s linear;
          }
        }
      }
    }

    .stopped {
      .rewind, .forward {
        display: none !important;
      }
      .progress-container {
        display: none !important;
      }
      .speed-container { 
        display: none !important;
      }
      
      .download {
        bottom: 5px;

        i {
          opacity: 0.75;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .loading{
      .play-button {
        animation-name: spin;
        animation-duration: 5000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }

    .playing {
      .rewind, .forward {
        display: inline-block !important;
        color: $fc-white;
      }

      .rewind:active {
        transform: rotate(-10deg) scale(1.1);
      }

      .forward:active {
        transform: rotate(10deg) scale(1.1);
      }

      .progress-container {
        display: flex !important;
      }
    }

    .paused {
      .rewind, .forward {
        display: inline-block !important;
        color: rgba($fc-white, 0.5);
      }
      .progress-container {
        display: flex !important;
      }
    }
  }

  .date-recorded {
    font-weight: 600;
    color: $fc-gold;
    margin-bottom: 0.25rem;
  }

  h3.title {
    letter-spacing: 1.6px;
  }

  .scripture-references {
    margin-bottom: 7px;
  }

  .notes-button {
    background-color: $fc-blue;
    color: #ffffff;
    font-size: 12px;
    padding: 7px 17px;
    margin: 2px 5px;
    max-height: 30px;
    border: none;
    border-radius: 15px;
  }

  .sermon-notes {
    display: none;
    border-top: solid 1px $fc-gold;
    padding-top: 10px;
    margin-top: 5px;
  }

  .scripture-ref {
    border-radius: 999px;
    background-color: $fc-blue;
    color: #ffffff;
    font-size: 12px;
    padding: 7px 17px;
    margin: 2px 5px;
    max-height: 30px;
    flex: 0 0 auto;

    img {
      margin-right: 9px;
    }

    a {
      color: $fc-white;
    }
  }

  .default-thumbnail {
    background-image: url(../images/default-sermon-thumbnail.png);
  }
}

.sermon-container-sm {
  max-width: 250px;
  padding: 25px 0px 0px 0px;
  margin: 0px 10px;

  .thumbnail-container {
    width: 250px;
    height: 170px;

    @include w-phone {
      // width: $w-small-phone;
    }
  }
}

.recent-sermons {
  margin: 75px 0px;

  .recent-sermon-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @include w-custom-max($w-small-phone) {
    .recent-sermon-row {
      // margin-left: 15px;
    }
  }

  .sermon-container {
    max-width: 400px;
    padding: 10px 0px 0px 0px;
    margin: 0px 10px;

    @include w-phone {
      max-width: $w-small-phone;
      padding: 0;
      margin: 0;
    }

    .thumbnail-container {
      width: 400px;
      height: 275px;

      @include w-phone {
        width: $w-small-phone;
        margin-left: 0;
        margin-right: 0;
      }
    }

    @include w-phone {
      .info-container {
        padding: 10px 5px 0px 5px;
      }
    }
  }
}

.sermon-list {
  margin: 15px 20px;


  .filter-row {
    label {
      margin-bottom: 0;
    }
  }

  .series-filter {
    margin-right: 15px;
    margin-left: 15px;
    max-width: 275px;
  }

  .btn-series-search {
    color: $fc-white;
    background-color: $fc-gold;

  }

  .sermon-container {
    max-width: 400px;
    padding: 45px 0px 0px 0px;
    margin: 0px 10px;

    &-sm {
      max-width: 250px;
      padding: 25px 0px 0px 0px;
      margin: 0px 10px;

      .thumbnail-container {
        width: 250px;
        height: 170px;
      }
    }

    @include w-phone {
      max-width: $w-small-phone;
      padding: 10px 5px 0px 5px;
    }

    .thumbnail-container {
      width: 400px;
      height: 275px;

      @include w-phone {
        width: $w-small-phone;
      }
    }

    @include w-phone {
      .info-container {
        padding: 10px 5px 0px 5px;

      }
    }
  }
}

