@use 'sass:math';
@import './variables.scss';

@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * math.div(((100vw - #{$min-vw}), #{strip-unit($max-vw - $min-vw)})));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin ministry-banner() {
  position: relative;
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin grow-anim($size: 1.1, $duration: 0.15s) {
  transition: all $duration ease-in-out;

  &:hover {
    transform: scale($size);
  }
}

@mixin w-phone {
  @media (max-width: $w-phone - 1) {
    @content;
  }
}

@mixin w-tablet {
  @media (min-width: $w-phone) and (max-width: $w-tablet) {
    @content;
  }
}

@mixin w-phone-tablet {
  @media (max-width: $w-tablet) {
    @content;
  }
}

@mixin w-custom-max($max) {
  @media(max-width: $max) {
    @content;
  }
}