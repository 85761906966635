@import './variables.scss';
@import './animations.scss';
@import './mixins.scss';
@import './typography.scss';
@import './forms.scss';
@import './util.scss';

@font-face {
  font-family: BeyondInfinity;
  src: url(../fonts/BeyondInfinity-Demo.ttf);
}

.fc-root {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  color: #000000;
}

h1, h2, h3, h4, h5, h6 {
  color: $fc-darkblue;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;

  @include w-phone {
    font-size: 18px;
  }
}

h2.wide {
  letter-spacing: 6px;

  @include w-phone {
    letter-spacing: 4px;
  }
}

.emphasized {

  position: relative;

  &:after {
    content:' ';
    position: absolute;
    top:100%;
    left:50%;
    width: 70%;
    border: 1.5px solid $fc-darkblue;
    border-radius:3px;
    box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
    transform:translateX(-50%);
  }
}

h3 {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 4px;
}

h1.fancy-header {
  font-family: BeyondInfinity;
  color: $fc-gold;
  font-size: 60px;
}

h4 {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

a {
  color: $fc-darkblue;
}

a.esv-crossref-link {
  color: $fc-darkblue !important;
}

.scripture-ref {
  a.esv-crossref-link {
    color: $fc-white !important;
  }
}

a.underline {
  text-decoration: underline;
}

blockquote {
  padding-left: 30px;
}

div.ccm-panel-content ul.nav, div.ccm-panel-content menu {
  display:block;
}

.ccm-edit-mode {
  #ccm-theme-grid-edit-mode-row-wrapper {
      display: flex;
      width: 100%;
  }
}
.ccm-edit-mode-block-menu.popover, .popover  {visibility: visible;}

.ccm-block-feature-item {
  margin-bottom: 15px !important;
}

#fc-login {
  background-color: black;
  padding-right: 5px;
  padding-bottom: 5px;
  text-align: right;

  a {
    color: #303030;
  }
}

.container-clear {
  padding: 0px 0px;
  margin: 0px 0px;
}

.card-row {
  margin-bottom: 30px;

  .card-padding {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 3px 20px rgba(0,0,0,0.16);
    padding: 60px;

  }
}

.visitor-overlap {
  margin-top: -50px;

  @include w-tablet {
    padding: 30px;
  }
}

.no-visitor-overlap-mobile {
  @include w-phone-tablet {
    margin-top: 5px;
  }
}

.white-bg {
  background-color: #ffffff;
}

.fc-navbar {
  background: $blue 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0,0,0, 0.16);
  padding: 0px 7.75%;
  min-height: 80px;

  @include w-phone-tablet {
    padding: 0px 15px;
    display: flex;
    align-items: center;
  }

  .logo {
    width: 185px;
    height: 63px;
  }
  ul.nav, .ccm-responsive-navigation ul {

    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      font: 14px Montserrat;
      line-height: 18px;
      letter-spacing: 1.4px;
      color: #FFFFFF;
      text-transform: capitalize;
      padding-left: 15px;
      padding-right: 15px;
      @include grow-anim();


      @include w-phone-tablet {
        padding-left: 5px;
        padding-right: 5px;
      }

      a {
        font: 14px Montserrat;
        line-height: 18px;
        letter-spacing: 1.4px;
        color: #FFFFFF;
        text-transform: capitalize;
        text-decoration: none;
      }

      a[href$="coronavirus"] {
        font-weight: bold;
        color: #fffdf2;
      }
    }
    .nav-selected {
      color: #ffffff;
    }
  }
}

.fc-footer {
  display: flex;
  flex-direction: column;

  .link-row {
    height: 170px;
  }
}

.fc-subpage-header-container {
  background-image: url(../images/bg/header1.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  padding: 75px 0px;
  height: 220px;

  .fc-subpage-header {
    color: #ffffff;
    text-align: center;
  }

  .fc-subpage-divider {
    width: 100px;
    height: 2px;
    margin: 10px auto -58px auto;
    background-color: #ffffff;
  }
}

.fc-main {

  .fc-hero {
    background-image: url(../images/bg/home4.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 100% auto;
    width: 100vw;
    height: 39vw;
    padding-top: 5vw;
    padding-left: 5vw;

    @include w-phone-tablet {
      padding-left: 15px;
    }

    .fc-tagline {
      text-align: right;
      max-width: 50vw;

      .prefix {
        text-align: left;
        font-family: Montserrat;
        @include fluid-type($min_width, $max_width, 12px, 36px);
        letter-spacing: 9px;
        color: white;
        text-transform: uppercase;
        opacity: 1;
        margin-right: 2vw;

        @include w-phone {
          letter-spacing: 0.75px;
        }

        @include w-tablet {
          letter-spacing: 3px;
        }

        @include w-custom-max($w-small-phone) {
          font-size: 11px;
        }
      }

      .suffix {
        text-align: right;
        font-family: BeyondInfinity;
        @include fluid-type($min_width, $max_width, 36px, 164px);
        letter-spacing: 0;
        color: white; // #29507E;
        opacity: 1;
        margin-top: -50px;

        @media (min-width: 426px) and (max-width: 768px) {
          margin-top: -20px;
        }

        @include w-phone {
          text-align: left;
          line-height: 1.25;
          margin-top: 0px;
          margin-left: 40px;
          font-size: 36px;
        }

        @include w-custom-max($w-small-phone) {
          font-size: 34px;
        }
      }

      .about-button-container {
        max-width: 35vw;
        margin-top: -40px;

        @include w-phone-tablet {
          display: none;
        }
      }

      .about-button {
        // margin-top: -40px;
        background-color: rgba(255, 255, 255, 0.7);
        display: inline-block;
      }
    }
  }
}

.fc-square-box {
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  position: relative;

  .fc-square-box-inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.fc-header-emphasis {
  width: 100px;
  height: 2px;
  margin: 10px 0px;
  background-color: $fc-darkblue;
}

.fc-day-grid {
  .alert-info {
    background-color: $fc-dropshadow;
  }
}

.fc-resources {

  .resource-col {
    .col-sm-6 {
      margin-bottom: 15px;
    }
  }

  .resource-thumb {
    img {
      @include w-phone {
        max-width: 360px;
        width: 100%;
        height: auto;
      }
    }
  }
}

.bold-links {
  a {
    text-decoration: underline;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.narrow-container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.nav-tabs {
  padding-top: 15px;
  margin-bottom: 15px;
}

@import './ministries.scss';
@import './visitors.scss';
@import './sermons.scss';
@import './card.scss';
@import './call-to-action.scss';
@import './navigation.scss';
@import './fullcalendar.scss';
@import './footer.scss';
