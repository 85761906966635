@import './variables.scss';
@import './mixins.scss';

.fc-footer {

  margin: 0;
  padding: 0;

  .link-row {
    height: 170px;
    background-image: url(../images/bg/footer.png);
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 25px 0px;

    @include w-phone {
      max-width: 100%;
      margin: 0;
    }

    .col {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .footer-link {
      text-decoration: none;
    }

    .stacked-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      @media (max-width: 500px) {
        img {
          width: 32px;
          height: 32px;
        }
        p {
          font-size: 16px;
        }
      }
    }

    .col:nth-child(2) {
      border-left: 1px solid $fc-blue;
      border-right: 1px solid $fc-blue;
    }

    p {
      font-family: Montserrat;
      text-transform: uppercase;
      color: $fc-darkblue;
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }
  }

  .info-row {
    height: 180px;
    background-color: black;
    color: white;

    @include w-phone {
      height: auto;
    }

    a {
      color: white;
    }

    .info-layout {
      max-width: 1000px;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: stretch;
      height: 100%;

      @include w-phone {
        padding-top: 10px;
      }
    }

    .link-col {
      font-family: Montserrat;
      font-size: 16px;
      color: white;
      text-transform: capitalize;
      text-align: center;
    }
  }

  .contact-row {
    @include w-phone {
      max-width: 100%;
      margin: 0;
      flex-direction: column;
    }
  }

  .footer-logo {
    // background-image: url(../images/logos/logo-footer.png);
    // background-repeat: no-repeat;
    // background-position-x: center;
    // width: 200px;
    // height: 68px;

    picture {
      width: 200px;
      height: auto;
    }

    @include w-phone {
      order: 0;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .address-col {

    @include w-phone {
      order: 1;
      margin-bottom: 15px;
    }
  }

  .social-col {
    @include w-phone {
      order: 2;
    }
  }
}
