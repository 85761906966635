@import './variables.scss';
@import './mixins.scss';

.card-container {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.16);

  .banner-container {

    height: auto;
    background-repeat: no-repeat;
    position: relative;

    .banner {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .banner-overlay {

      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba($fc-darkblue,0.8);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      z-index: 1;

      h2 {
        margin-top: 22px;
        text-transform: none;
        font-family: BeyondInfinity;
        font-size: 60px;
        color: $fc-gold;
        font-weight: 500;

        @include w-phone {
          font-size: 48px;
        }
      }
    }
  }

  .card-content {
    padding: 10px;
  }
}