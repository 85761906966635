$blue: #2381C4;

$fc-white: #ffffff;
$fc-blue: #2381C4;
$fc-darkblue: #29507E;
$fc-dropshadow: rgba(0, 0, 0, 0.16);
$fc-mediumgray: #707070;
$fc-gold: #CCBE91;
$fc-lightgray: #EFEFEF;

$fc-border-radius: 8px;

$min_width: 360px;
$max_width: 1200px;

$w-phone: 425px;
$w-tablet: 768px;
$w-small-phone: 360px;
$fa-font-path: "../fonts";