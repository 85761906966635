@import './variables.scss';

.fc-event {
  background-color: transparent;
  color: $fc-darkblue;
  border-color: transparent;
  margin: 3px 2px;

  &:hover {
    color: $fc-darkblue;
  }
}

.fc-event-dot {
  background-color: $fc-blue;
}