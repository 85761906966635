@import './variables.scss';
@import './mixins.scss';

.large-text {
  font-size: 20px;

  @include w-phone {
    font-size: 14px;
  }
}

.fc-zebra {

  li:nth-child( odd ) {
    background-color: $fc-lightgray;

  }

  li:nth-child( even ) {
    background-color: #DEDEDE;
  }
}