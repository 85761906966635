@import './variables.scss';
@import './util.scss';
@import './mixins.scss';

.ministries {

  .full-height {
    height: 100%;
  }

  .ministry {

    flex-grow: 0;

    .cell-size {
      width: 300px;
      height: 300px;
    }

    .img-bg {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .youth {
      background-image: url(../images/ministries/card-youth-ministries.png);
    }

    .awana {
      background-image: url(../images/ministries/card-awana.png);
    }

    .missions {
      background-image: url(../images/ministries/card-missions.png);
    }

    .women {
      background-image: url(../images/ministries/card-womens-ministries.png);
    }

    .men {
      background-image: url(../images/ministries/card-mens-ministries.png);
    }

    .adults {
      background-image: url(../images/ministries/card-adults.png);
    }

    .children {
      background-image: url(../images/ministries/card-children.png);
    }

    .sunday-school {
      background-image: url(../images/ministries/card-sunday-school.png);
    }

    .name {
      letter-spacing: 4px;
      color: $fc-gold;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
    }

    &-banner-youth {
      background-image: url(../images/ministries/banner-youth-ministries.png);
      @include ministry-banner();

      h2 {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 150px;
        line-height: 150px;
        background-color: rgba($fc-darkblue, 0.8);
        text-transform: none;
        font-family: BeyondInfinity;
        font-size: 60px;
        color: $fc-gold;
        text-align: center;

      }
    }
  }

  img {
    width: 100%;

  }

  .banner-children {
    background-image: url(../images/ministries/banner-children.png);
    @include ministry-banner();
  }

  .banner-awana {
    background-image: url(../images/ministries/banner-awana.png);
    @include ministry-banner();
  }

  .banner-adults {
    background-image: url(../images/ministries/banner-adults.png);
    @include ministry-banner();
  }

  .banner-sunday-school {
    background-image: url(../images/ministries/banner-sunday-school.png);
    @include ministry-banner();
  }
}