.grow { transition: all .15s ease-in-out; }
.grow:hover { transform: scale(1.1); }

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}